import {
    Switch,
    Route
} from "react-router-dom";

import App from './App'
import Info from './Pages/Info'

const Routes = () => (
    <Switch>
        <Route exact path="/">
            <App />
        </Route>
        <Route path="/info">
            <Info />
        </Route>
    </Switch>
)

export default Routes