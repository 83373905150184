import {
  Link
} from "react-router-dom";
import styled from "styled-components"
import Modal from './Modal'
import { device } from "./theme"

import Logo from "./logo-lockup.svg"


const Container = styled.div`
  position: absolute;
  width: 100%;
  z-index: 10;
`
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 40px;
  align-items: center;

  @media ${device.tablet} {
    padding: 20px;
  }
`

const LogoImage = styled.img`
  height: 55px;
`

const MenuItems = styled.div`
  display: flex;
  align-items: center;

  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
`
const Item = styled.div`
  color: #fff;
  padding: 30px;
  cursor: pointer;

  @media ${device.tablet} {
    padding: 10px 5px;
  }
`

const Navbar = ({ white }) => (
  <Container white={white}>
    <Content>
      <StyledLink to="/">
        <LogoImage src={Logo} alt="Argon Industries" />
      </StyledLink>
      <MenuItems>
        <a href="https://drev4l.com" rel="noopener noreferrer" target="_blank">
            <Item>
                Current Project   
            </Item>
        </a>
        <StyledLink to="/info">
            <Item>
                Info
            </Item>
        </StyledLink>
        <Modal trigger={
            <Item>
                Contact
            </Item>
            }
            content={<>
              <div><strong>Direct all questions to:</strong></div>
              <div>info@argon.industries</div>
              <br/>
              <div><strong>Direct business inquiries to:</strong></div>
              <div>business@argon.industries</div>
            </>} 
        />
        
      </MenuItems>
    </Content>
  </Container>
)

export default Navbar
