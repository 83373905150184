import styled from "styled-components"
import { device } from "../theme"

const Content = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media ${device.desktop} {
    width: 60%;
    max-width: 700px;
  }

  @media ${device.tablet} {
    width: 80%;
  }
`

const Title = styled.h2`
    font-style: italic;
    font-weight: 100;
`

const Description = styled.p`
    font-size: 18px; 
    line-height: 28px;   
`

const Info = () => {
  return (
    <div className="App">
      <header className="App-header">  
        <Content>
          <Title>We are a hybrid creative tech studio with blockchain solutions.</Title>
          <Description>Our team is a mix of industry leading creatives and engineers evolving from a history of brand strategy, creative direction, decentralized apps and defi solutions, full-stack engineering, branding and product design.</Description>
        </Content>
      </header>
    </div>
  );
}

export default Info;