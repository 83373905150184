import styled from "styled-components"
import Mailchimp from 'react-mailchimp-form';
import { device, theme } from "./theme"
import logo from './logo.svg';

import './App.css';

const Logo = styled.img`
  height: 100%;
  padding: 0px 20%;
  pointer-events: none;

  @media ${device.tablet} {
    padding: 0px 10%;
  }
`

const MailChimpContainer = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: hidden;

`

const StyledMailchimp = styled(Mailchimp)`
  width: 100%;
  text-align: center;
  font-size: 16px;

  & input {
    padding: 1.2em 1.5em;
    border-radius: 0px;
    border: 0;
    width: 60%;

    @media ${device.desktop} {
      width: 40%;
    }

    @media ${device.tablet} {
      width: 100%;
    }
  }
  & button {
    padding: 1em 1.5em;
    color: #fff;
    background-color: ${theme.secondary};
    border: 0;
    outline: none;
    font-family: "Space Mono", monospace;
    cursor: pointer;

    &:hover {
      opacity: 0.75;
    }

    @media ${device.tablet} {
      width: 100%;
    }
  }
`

const Content = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media ${device.desktop} {
    width: 60%;
    max-width: 700px;
  }

  @media ${device.tablet} {
    width: 80%;
  }
`

function App() {
  return (
    <div className="App">
      <header className="App-header">  
        <Content>
          <Logo src={logo} alt="logo" />
          <MailChimpContainer>
          <StyledMailchimp
              action={'https://industries.us1.list-manage.com/subscribe/post?u=d67b863c277c728789713af57&amp;id=31b38e7c78'}
              fields={[
                {
                  name: 'EMAIL',
                  placeholder: 'Email',
                  type: 'email',
                  required: true
                }
              ]}
              messages = {
                {
                  sending: "Sending...",
                  success: "Thank you for subscribing!",
                  error: "An unexpected internal error has occurred.",
                  empty: "You must write an e-mail.",
                  duplicate: "Too many subscribe attempts for this email address",
                  button: "Join our mailing list"
                }
              }
              />
          </MailChimpContainer>
        </Content>
        
      </header>
    </div>
  );
}

export default App;
